<template>
  <v-menu
    :close-on-content-click="false"
    :return-value.sync="selectedColor"
    min-width="290px"
    offset-y
    ref="menu"
    transition="scale-transition"
    v-model="menu"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :dense="dense"
        :label="label"
        :outlined="outlined"
        :rules="rules"
        readonly
        v-model="selectedColor"
        v-on="on"
      >
        <span slot="prepend-inner">
          <v-icon v-bind:style="{ color: selectedColor }">label</v-icon>
        </span>
      </v-text-field>
    </template>
    <v-color-picker
      class="elevation-0"
      no-title
      scrollable
      mode="hexa"
      hide-mode-switch
      v-model="selectedColor"
    />
    <div class="text-right white">
      <v-btn
        @click="$refs.menu.save(selectedColor) & $emit('input', selectedColor)"
        color="primary"
        >{{ $t('generic.lang_ok') }}</v-btn
      >
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'colorPicker',
  props: {
    label: {
      type: String,
      default: 'Color',
    },
    color: {
      type: String,
      default: '#FF00FF',
    },
    rules: Array,
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'hexa',
    },
  },
  data: () => {
    return {
      menu: null,
      selectedColor: '#FF00FF',
    };
  },
  watch: {
    selectedColor: function (val) {
      this.$emit('color', val);
      this.$emit('input', val);
    },
    color: function (val) {
      this.selectedColor = val ?? '#FF00FF';
    },
  },
  mounted() {
    this.selectedColor = this.color;
    this.$emit('input', this.color);
  },
};
</script>

<style scoped></style>
