export default {
    data() {
        return {
            preventSpecialCharsRegex:/[^A-Za-z0-9-_ßäöüÄÖÜ:,+.€%$ ]+/g,
            numbersRegex:/^[0-9]*$/,
            rules: {
                min: v => v && v.length >= 5 || 'Min 5 characters',
                minFour: v => v && v.length >= 4 || 'Min 4 characters',
                maxChars: (v,max) => v && v.length <= max || 'Max '+max+' characters',
                minChars: (v,min) => v && v.length >= min || 'min '+min+' characters',
                isNumber: (v) => (!isNaN(Number(v))) || this.$t("generic.lang_invalid_input"),
                maxNumbers: (v,max) => (v && Number(v) && v.length <= max )|| this.$t("generic.lang_invalid_input"),
                numberRange: (v,min,max) => (v && Number(v)>=min && Number(v)<=max )|| this.$t("generic.lang_numberRangeInput").replace("${min}",min).replace("${max}",max),
                required: (v) => !!String(v) || this.$t("generic.lang_requiredField"),
                checkZero : (v) => (Number(v)!==0) || this.$t("generic.lang_invalid_input"),
                ipAddress : (v) => {
                    let pattern = /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;

                    return pattern.test(v) || this.$t("generic.lang_invalid_ip_address")
                },
                email : (v)=>{
                    let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                    return pattern.test(v) || this.$t("generic.lang_invalidEmail");
                },
                https:(v='')=>{ return (v && v.indexOf("https://") === 0) || 'an https link is required' },
                uuid :(v)=>{
                    const pattern=/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
                    // REMOVING PREFIXES FROM SCANNED GIFT CARD CODES
                    const tmp = v?.replaceAll('GF$','');
                    return pattern.test(tmp)||this.$t('generic.lang_wrongGiftCardNumber')
                }
            }
        }
    },
    methods: {
        /*
        IT MUST BE USED IN THE KEYPRESS EVENT OF ANY TEXT FIELD LIKE INPUT TO PREVENT THE ENTERING OF SPECIAL CHARS
        * */
        preventSpecialChars(event) {
            if ((new RegExp(this.preventSpecialCharsRegex).test(event.key)))
                event.preventDefault();
        },
        acceptOnlyNumbers(event) {
            if (isNaN(event.key))
                event.preventDefault();
        },

    }
}