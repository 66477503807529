<template>
  <v-container fluid class="pa-0">
    <v-card elevation="0">
      <v-card-text>
        <div class="pa-0 px-0 ma-0">
          <div class="pa-0 px-0 ma-0">
            <v-btn class="mr-0" color="grey darken-2" outlined @click="
                setCurrentWeek(0);
                getPlans();
              ">
              {{ $t('generic.lang_thisWeek') }}
            </v-btn>
            <v-btn color="grey darken-2" fab icon text :disabled="loadingItems" @click="
                setCurrentWeek(-1);
                getPlans();
              ">
              <v-icon large> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn class="elevation-0 text-black border" color="primary" fab small @click="searchDatePicker = true">
              {{ (new Date(searchDate)).getDate() }}
            </v-btn>
            <v-btn color="grey darken-2" fab icon small :disabled="loadingItems" @click="
                setCurrentWeek(1);
                getPlans();
              ">
              <v-icon large> mdi-chevron-right </v-icon>
            </v-btn>
            <strong class="font-weight-bolder">
              {{ formatedFirstDay + ' - ' + formatedLastDay }}
            </strong>
            <v-spacer></v-spacer>
          </div>
        </div>
      </v-card-text>
      <v-divider class="pa-0 ma-0" />
      <v-card-text v-if="this.$vuetify.breakpoint.mdAndUp && this.curr" class="pa-0">
        <v-progress-linear indeterminate v-if="this.loadingItems" />
        <v-row no-gutters>
          <v-col v-for="day in 7" :key="day" style="width: 14% !important">
            <v-card outlined tile>
              <v-card-title class="primary--text pb-2 text-capitalize" style="font-size: 1.2em">
                {{ getTranslatedDay(day).replaceAll('.','') }}
                <v-spacer />
                <!-- <v-icon @click="open_note(getDay(day))">note_add</v-icon> -->
              </v-card-title>
              <v-divider class="pa-0 ma-0" />
              <v-card-text class="pa-1">
                <v-btn block class="mx-auto ma-auto" color="primary" height="100" outlined
                  @click="open(getDay(day), day)">
                  <v-icon class="font-size-xlg" style="font-size: 50px !important">
                    add
                  </v-icon>
                </v-btn>
                <template v-if="
                    menuItems[getUntranslatedWeekDays()[day - 1]] &&
                    menuItems[getUntranslatedWeekDays()[day - 1]].length
                  ">
                  <template v-for="(item, j) in menuItems[
                      getUntranslatedWeekDays()[day - 1]
                    ]">
                    <v-card v-if="!checkIfExcluded(item, getFormatedDate(day))" :key="j"
                      class="ma-auto extra-card-mobile text-black mt-2 image_size" elevation="0" min-width="100%"
                      outlined>
                      <v-chip class="text-uppercase font-weight-bold" style="
                          position: absolute;
                          right: 5px;
                          top: 5px;
                          z-index: 2;
                        " v-if="checkIfCanceled(item, getFormatedDate(day))" color="error">
                        {{ $t('generic.lang_canceled') }}
                      </v-chip>
                      <v-img height="100" :src="item.image">
                        <v-overlay v-if="checkIfCanceled(item, getFormatedDate(day))"
                          :value="checkIfCanceled(item, getFormatedDate(day))" :absolute="true" color="white"
                          opacity="0.65">
                        </v-overlay>
                      </v-img>

                      <v-divider class="ma-0" />
                      <v-card-title class="pa-0 font-weight-bold" style="font-size: 1em !important; line-height: 1.3em">
                        {{ item.eventName }}
                      </v-card-title>
                      <v-card-text class="pa-0">
                        <v-list dense class="pa-0">
                          <v-list-item style="min-height: 32px" v-if="item.fromTime && item.toTime" class="px-1">
                            <v-list-item-icon class="pa-0 ma-0 my-1">
                              <v-icon size="16">
                                mdi-clock-time-four-outline
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="py-0">
                              <v-list-item-title class="text-uppercase primary--text font-weight-bold" style="
                                  font-size: 0.9em !important;
                                  line-height: 1.3em;
                                ">
                                {{
                                item.fromTime.toString().substring(0, 5) +
                                ' ' +
                                $t('generic.lang_to') +
                                ' ' +
                                item.toTime.toString().substring(0, 5)
                                }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item style="min-height: 32px" v-if="item.location" class="px-1">
                            <v-list-item-icon class="pa-0 ma-0 my-1">
                              <v-icon size="16">
                                mdi-map-marker-outline
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="py-0 font-weight-bold error--text" style="line-height: 1.2em">
                              {{ item.location }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item style="min-height: 32px" v-if="item.providerName" class="px-1">
                            <v-list-item-icon class="pa-0 ma-0 my-1">
                              <font-awesome-icon class="mt-1" style="font-size: 15px" :icon="['fal', 'user-headset']" />
                            </v-list-item-icon>
                            <v-list-item-content class="py-0">
                              <v-list-item-title class="purple--text">
                                {{ item.providerName }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item style="min-height: 32px" class="px-1">
                            <v-list-item-icon class="pa-0 ma-0 my-1">
                              <v-icon size="16" color="primary">
                                mdi-ticket-outline
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="py-0">
                              <v-list-item-title class="primary--text">
                                {{ item.kontingent }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon class="pa-0 ma-0">
                              <v-btn class="ma-0" fab x-small icon @click="showBulkSendEmailDialog(item, day)">
                                <font-awesome-icon class="mt-1" style="font-size: 18px" :icon="['fal', 'mail-bulk']" />
                              </v-btn>
                            </v-list-item-icon>
                          </v-list-item>
                          <v-list-item style="min-height: 32px" v-if="item.tags && item.tags.length > 0" class="px-1">
                            <v-list-item-icon class="pa-0 ma-0 my-1">
                              <font-awesome-icon class="mt-1" style="font-size: 15px" :icon="['fal', 'tag']" />
                            </v-list-item-icon>
                            <v-list-item-content class="py-0">
                              <v-list-item-title class="accent-2--text" v-for="(tag, ind) in item.tags" :key="ind">
                                {{ tag }},
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-row no-gutters>
                          <v-col class="text-left pa-0 px-0" cols="6">
                            <v-icon @click="checkDelete(item, getFormatedDate(day))"
                              class="text-danger mx-auto">mdi-delete
                            </v-icon>
                          </v-col>
                          <v-col class="text-right pointer pa-0" cols="6">
                            <v-icon @click="setUpEdit(item)" class="text-primary bg-grey">create</v-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- search by date  -->
    <v-dialog v-model="searchDatePicker" max-width="400px" scrollable>
			<v-card align="center" class="pa-0 ma-0" width="100%">
				<v-card-title class="pa-0 px-0 ma-0">
					<v-spacer />
					<v-btn icon @click="searchDatePicker = false">
						<v-icon color="error"> close </v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pa-0 ma-0">
					<v-date-picker v-model="searchDate" event-color="green lighten-1" full-width :first-day-of-week="1" :allowed-dates="allowedDates" width="100%"></v-date-picker>
				</v-card-text>
			</v-card>
		</v-dialog>
    <!--
    select delete type
    -->
    <v-dialog v-model="selectDeleteType" max-width="500" scrollable>
      <v-card>
        <v-card-title class="card-header">
          {{ $t('generic.lang_deleteRecurringItems') }}
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item @click="
                deleteItem(
                  selectedItem.uuid,
                  'exclude',
                  selectedDay.getTime() / 1000
                ) & (selectDeleteType = false)
              ">
              <v-list-item-icon>
                <v-radio />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('generic.lang_onlyThisArticl') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="
                deleteItem(selectedItem.uuid, 'full') &
                  (selectDeleteType = false)
              ">
              <v-list-item-icon>
                <v-radio />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('generic.lang_thisAndOthers') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- new item  -->
    <v-dialog v-model="dialog" fullscreen persistent>
      <v-card>
        <v-card-title :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" class="card-header">
          {{ $t('eventbee.lang_newPlan') }}
        </v-card-title>
        <v-card-text class="overflow-auto">
          <v-form ref="form" lazy-validation>
            <v-row class="pt-4" no-gutters>
              <v-col cols="12" class="pt-6 pb-5">
                <v-card-title class="pa-0">
                  {{ $t('eventbee.lang_ticketInfo') }} :
                </v-card-title>
                <v-divider/>
              </v-col>
              <v-col class="pr-1 pl-1" cols="12" sm="6">
                <v-select v-model="selectedEventGroup" :items="eventGroups" dense item-text="name" item-value="id"
                  :label="$t('eventbee.lang_eventGroup')" outlined>
                </v-select>
              </v-col>

              <v-col class="pr-1 pl-1" cols="12" sm="6">
                <v-autocomplete :disabled="!!!this.selectedEventGroup" v-model="form.eventUUID"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" :items="filteredEvents"
                  deletable-chips chips :label="this.$t('datatables.lang_search')" :rules="[(v) => !!v]"
                  append-icon="search" dense item-text="eventName" item-value="uuid" outlined>
                </v-autocomplete>
              </v-col>

              <v-col class="pr-1 pl-1" cols="12" sm="9">
                <v-text-field v-model="form.alias" dense :label="$t('eventbee.lang_ticket_alias')" outlined
                  append-icon="mdi-refresh" @click:append="
                    () => {
                      form.alias = (selectedEventDetails || {}).eventName;
                    }
                  ">
                </v-text-field>
              </v-col>

              <v-col class="pr-1 pl-1" cols="12" sm="3">
                <color-picker outlined dense v-model="form.color" :color="form.color"></color-picker>
              </v-col>
              <v-col class="pr-1 pl-1" cols="12" sm="6">
                <v-select v-model="form.overrideEventGroup" :items="eventGroups" dense clearable item-text="name"
                  item-value="id" :label="$t('eventbee.lang_overrideEventGroup')" outlined>
                </v-select>
              </v-col>
              <v-col v-if="gettingTheEvent" cols="12" class="text-center">
                <!-- add loader for getting event details -->
                <v-progress-circular indeterminate color="primary" class="mx-auto"></v-progress-circular>
              </v-col>
              <v-expand-transition v-else-if="selectedEventDetails && !gettingTheEvent">
                <v-row no-gutters>
                  <v-col cols="12" class="pt-6 pb-5">
                    <v-card-title class="pa-0">
                      {{ $t('eventbee.lang_planing') }} :
                    </v-card-title>
                    <v-divider/>
                  </v-col>

                  <v-col class="pr-1 pl-1" cols="6" sm="6">
                    <BaseTimeInput v-model="form.fromTime" :rules="[(v) => !!v]" dense :label="$t('generic.lang_from')"
                      outlined prepend-inner-icon="mdi-clock-time-four-outline" />
                  </v-col>
                  <v-col class="pr-1 pl-1" cols="6" sm="6">
                    <BaseTimeInput v-model="form.toTime" :rules="[(v) => !!v]" dense :label="$t('generic.lang_to')"
                      outlined prepend-inner-icon="mdi-clock-time-four-outline" />
                  </v-col>
                  <v-col cols="12" align="center">
                    <h6 class="px-0 mb-2 text-center">
                      {{ $t('eventbee.lang_alternativeTimes') }}
                    </h6>
                  </v-col>
                  <v-col class="pr-1 pl-1" cols="6" sm="6">
                    <BaseTimeInput v-model="form.fromTime2" dense :label="$t('generic.lang_from')" outlined
                      prepend-inner-icon="mdi-clock-time-four-outline" />
                  </v-col>
                  <v-col class="pr-1 pl-1" cols="6" sm="6">
                    <BaseTimeInput v-model="form.toTime2" dense :label="$t('generic.lang_to')" outlined
                      prepend-inner-icon="mdi-clock-time-four-outline" />
                  </v-col>

                  <v-col class="pr-1 pl-1" cols="12">
                    <v-menu max-width="300" offset-y top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-bind="attrs" v-on="on" :value="calendar" dense outlined
                          prepend-inner-icon="insert_invitation" readonly>
                          <!--                  <template v-slot:append>
                                              <v-dialog max-width="400px" overlay-opacity="0.9">
                                                <template v-slot:activator="{on, attrs}">
                                                  <v-btn v-bind="attrs" v-on="on" icon style="position: absolute; top: -4px; right: 0">
                                                    <v-icon color="primary">
                                                      edit
                                                    </v-icon>
                                                  </v-btn>
                                                </template>
                                                <v-card>
                                                  <v-card-text>
                                                    sdf
                                                  </v-card-text>
                                                </v-card>
                                              </v-dialog>
                                            </template>-->
                        </v-text-field>
                      </template>
                      <v-card v-if="this.curr">
                        <v-card-text>
                          <v-list>
                            <v-list-item-group>
                              <v-list-item @click="selectDay(getUntranslatedWeekDays())">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('generic.lang_everyDay') }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click="
                              selectDay(
                                getUntranslatedWeekDays()[selectedDay.getDay()],
                                'week'
                              )
                            ">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('generic.lang_every') }}
                                    <span class="primary--text">{{
                                      getUntranslatedWeekDays()[
                                      selectedDay.getDay()
                                      ]
                                      }}</span>
                                    {{ $t('generic.lang_weekly') }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click="
                              selectDay(
                                getUntranslatedWeekDays()[selectedDay.getDay()],
                                'month',
                                1
                              )
                            ">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('generic.lang_everyFirst') }}
                                    <span class="primary--text">{{
                                      weekDays[selectedDay.getDay()]
                                      }}</span>
                                    {{ $t('generic.lang_monthly') }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click="
                              selectDay(
                                getUntranslatedWeekDays()[selectedDay.getDay()],
                                'month',
                                2
                              )
                            ">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('generic.lang_everySecond') }}
                                    <span class="primary--text">{{
                                      weekDays[selectedDay.getDay()]
                                      }}</span>
                                    {{ $t('generic.lang_monthly') }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click="
                              selectDay(
                                getUntranslatedWeekDays()[selectedDay.getDay()],
                                'month',
                                3
                              )
                            ">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('generic.lang_everyThird') }}
                                    <span class="primary--text">{{
                                      weekDays[selectedDay.getDay()]
                                      }}</span>
                                    {{ $t('generic.lang_monthly') }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click="
                              selectDay(
                                getUntranslatedWeekDays()[selectedDay.getDay()],
                                'month',
                                4
                              )
                            ">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('generic.lang_everyLast') }}
                                    <span class="primary--text">{{
                                      weekDays[selectedDay.getDay()]
                                      }}</span>
                                    {{ $t('generic.lang_monthly') }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click="openCustomCalendar">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('generic.lang_custom') }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" class="pt-6 pb-5">
                    <v-card-title class="pa-0">
                      {{ $t('eventbee.lang_ticketValiditySettings') }} :
                    </v-card-title>
                    <v-divider/>
                  </v-col>
                  <v-col class="px-1" cols="12" sm="6">
                    <v-select v-model="form.validTill" :items="expireItems" dense mandatory
                      :hint="$t('eventbee.lang_noneMsg')" item-text="name" item-value="value"
                      :label="$t('eventbee.lang_ticketValidFor')" outlined>
                      <template v-slot:append-outer>
                        <v-tooltip top nudge-left="100">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                          </template>
                          <span>
                            {{ $t("eventbee.lang_ticketValidTillTooltip") }}
                          </span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col v-if="form.validTill === 'date'" class="px-1 d-flex" cols="12" sm="6">
                    <BaseDateInput type="date" dense outlined v-model="form.validTillDate"
                      :label="$t('generic.lang_expiryDate')" />

                    <v-tooltip top nudge-left="100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2 mb-6" color="primary" dark v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("eventbee.lang_validTillDateTooltip") }}
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col class="px-1 d-flex" cols="12">
                    <v-switch class="mr-1" dense outlined :label="$t('generic.lang_askValidFrom')" v-model="form.askValidFrom" />
                    
                    <v-tooltip top nudge-bottom="20" nudge-left="100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("eventbee.lang_askValidFromTooltip") }}
                      </span>
                    </v-tooltip>
                  </v-col>

                  <v-col cols="12" class="pt-6 pb-5">
                    <v-card-title class="pa-0">
                      {{ $t('generic.lang_availablePlacesAndSlots') }} :
                    </v-card-title>
                    <v-divider/>
                  </v-col>
                  <template>
                    <v-col class="pr-1 pl-1" cols="12" sm="6">
                      <v-select v-model="form.seatingMapUUID" return-object clearable dense outlined :loading="loadingSeatMaps" @change="updateKontingent"
                        :items="filteredSeatMaps" :label="$t('eventbee.lang_seatingMap')" item-value="uuid" item-text="name">
                        <template v-slot:append-outer>
                          <v-tooltip top nudge-left="100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                            </template>
                            <span>
                              {{ $t("eventbee.lang_seatingMapUuidTooltip") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-select>
                    </v-col>
                  </template>
                  <template v-if="showEntryTicketFields">
                    <v-col class="pr-1 pl-1 d-flex" cols="6" sm="4">
                      <v-switch class="mt-1 ml-5 mr-1" v-model="form.is_open_day" :label="$t('eventbee.lang_is_open_day')" dense
                        outlined prepend-inner-icon="mdi-ticket-outline"></v-switch>

                      <v-tooltip top nudge-bottom="20" nudge-left="100">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mb-7" color="primary" dark size="20" v-bind="attrs" v-on="on">
                            mdi-information
                          </v-icon>
                        </template>
                        <span>
                          {{ $t("eventbee.lang_isOpenDayTooltip") }}
                        </span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="pr-1 pl-1 d-flex" cols="6" sm="4">
                      <v-switch class="mr-1" v-model="form.hide_slot_end_time"
                        :label="$t('eventbee.lang_hideEndTimeInSlots')" dense outlined
                        prepend-inner-icon="mdi-ticket-outline"></v-switch>

                      <v-tooltip top nudge-bottom="20" nudge-left="100">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                            mdi-information
                          </v-icon>
                        </template>
                        <span>
                          {{ $t("eventbee.lang_hideSlotEndTimeTooltip") }}
                        </span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="pr-1 pl-1 d-flex" cols="6" sm="4" v-if="form.is_open_day">
                      <v-switch class="mr-1" v-model="form.has_arrival_time"
                        :label="$t('eventbee.lang_has_arrival_time')" dense outlined
                        prepend-inner-icon="mdi-ticket-outline"></v-switch>

                      <v-tooltip top nudge-bottom="20" nudge-left="100">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                            mdi-information
                          </v-icon>
                        </template>
                        <span>
                          {{ $t("eventbee.lang_hasArrivalTimeTooltip") }}
                        </span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="pr-1 pl-1" cols="12" sm="6">
                      <v-text-field v-model="form.kontingent" :rules="[(v) => !!v]" dense :label="quotaFieldLabel"
                        outlined prepend-inner-icon="mdi-ticket-outline" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @focus="showTouchKeyboard">
                        <template v-slot:append-outer>
                          <v-tooltip top nudge-left="100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                            </template>
                            <span>
                              {{ $t("eventbee.lang_kontingentTooltip") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col class="pr-1 pl-1" cols="12" sm="6" v-if="!form.is_open_day">
                      <v-text-field v-model="form.number_of_slots" :rules="[(v) => Number(v) > 0]" dense
                        :label="$t('generic.lang_number_of_slots')" outlined type="number" :min="1"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard">
                        <template v-slot:append-outer>
                          <v-tooltip top nudge-left="100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                            </template>
                            <span>
                              {{ $t("eventbee.lang_numberOfSlotsTooltip") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col class="pr-1 pl-1" cols="12" sm="6"
                      v-if="(!form.is_open_day ||(form.has_arrival_time && form.is_open_day))">
                      <v-text-field v-model="form.calendar_steep" :rules="[(v) => Number(v) >= 0]" dense
                        :label="$t('generic.lang_timeChooserStep')" outlined type="number" :min="0"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard">
                        <template v-slot:append-outer>
                          <v-tooltip top nudge-left="100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                            </template>
                            <span>
                              {{ $t("eventbee.lang_timeChooserStepTooltip") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col class="pr-1 pl-1" cols="12" sm="6"
                      v-if="(!form.is_open_day ||(form.has_arrival_time && form.is_open_day))">
                      <v-text-field v-model="form.latestBookingBeforeClosing" :rules="[(v) => Number(v) >= 0]" dense
                        :label="$t('eventbee.lang_lastArrivalBeforeClosing')" outlined type="number" :min="0"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard">
                        <template v-slot:append-outer>
                          <v-tooltip top nudge-left="100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                            </template>
                            <span>
                              {{ $t("eventbee.lang_latestBookingBeforeClosingTooltip") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col class="pr-1 pl-1" cols="12" sm="6">
                      <v-text-field v-model="form.kontingent" :rules="[(v) => !!v]" dense :label="quotaFieldLabel"
                        outlined prepend-inner-icon="mdi-ticket-outline" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @focus="showTouchKeyboard">
                        <template v-slot:append-outer>
                          <v-tooltip top nudge-left="100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                            </template>
                            <span>
                              {{ $t("eventbee.lang_kontingentTooltip") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="pr-1 pl-1" cols="12" sm="6">
                      <v-select v-model="form.providerID" clearable dense outlined :items="serviceProviders"
                        :label="$t('generic.lang_provider')" item-value="userID" item-text="fullName">
                        <template v-slot:prepend-inner>
                          <font-awesome-icon class="mt-1" style="font-size: 1.3em" :icon="['fal', 'user-headset']" />
                        </template>
                        <template v-slot:append-outer>
                          <v-tooltip top nudge-left="100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                            </template>
                            <span>
                              {{ $t("eventbee.lang_providerIdTooltip") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox multiple :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                        v-model="form.tags" label="Tags" dense chips outlined deletable-chips class="tag-input"
                        :search-input.sync="search" @keyup.tab="updateTags" @paste="updateTags">
                        <template v-slot:prepend-inner>
                          <font-awesome-icon class="mt-1" style="font-size: 15px" :icon="['fal', 'tag']" />
                        </template>
                        <template v-slot:append-outer>
                          <v-tooltip top nudge-left="100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                            </template>
                            <span>
                              {{ $t("eventbee.lang_tagsTooltip") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-combobox>
                    </v-col>
                  </template>

                  <v-col cols="12" class="pt-6">
                    <v-card-title class="pa-0">
                      {{ $t('generic.lang_otherSettings') }} :
                    </v-card-title>
                    <v-divider/>
                  </v-col>

                  <v-col class="pr-1 pl-1 d-flex" cols="12">
                    <v-switch v-model="form.block_event_after_booking"
                      :label="$t('eventbee.lang_blockEventAfterBooking')" dense outlined
                      prepend-inner-icon="mdi-ticket-outline" class="mr-1"></v-switch>

                    <v-tooltip top nudge-bottom="20" nudge-left="100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("eventbee.lang_blockEventAfterBookingTooltip") }}
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="form.assignedPool" outlined :label="$t('eventbee.lang_eventPools')" dense
                      clearable item-value="uuid" item-text="name" :items="pools">
                      <template v-slot:append-outer>
                        <v-tooltip top nudge-left="100">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                          </template>
                          <span>
                            {{ $t("eventbee.lang_assignedPoolTooltip") }}
                          </span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" class="d-flex">
                    <v-switch dense outlined :label="$t('generic.lang_overridePrices')"
                      v-model="form.overrideEventPriceList" class="mr-1"></v-switch>

                    <v-tooltip top nudge-bottom="20" nudge-left="100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("eventbee.lang_overrideEventPriceListTooltip") }}
                      </span>
                    </v-tooltip>
                  </v-col>

                  <v-col v-if="form.overrideEventPriceList" cols="12">
                    <TicketsPriceListComponent v-model="form.priceList" class="mb-12" />
                  </v-col>
                </v-row>
              </v-expand-transition>
              <v-col v-else cols="12">
                <v-alert type="info">
                  {{ "please select an event first to load the plan's settings" }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
          :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidthExpanded" />
      </div>
      <div :class="[this.$vuetify.theme.dark ? 'dark-bg' : 'white']"
        style="position:sticky;bottom: 0;width: 100%;z-index: 1;">
        <v-divider class="ma-0" />
        <v-card-actions class="card-footer d-flex justify-space-between pt-1 pb-1">
          <v-btn color="error" elevation="0" @click="close">
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>
          <v-btn v-if="!edit" :loading="loadingItems" :disabled="loadingItems" color="primary" outlined @click="create">
            {{ this.$t('generic.lang_create') }}
          </v-btn>

          <v-btn v-else :loading="loadingItems" :disabled="loadingItems" color="primary" outlined @click="create">
            {{ this.$t('generic.lang_edit') }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-dialog>

    <!-- select date range -->
    <v-dialog persistent v-model="range_dialog" max-width="400" overlay-opacity="0.9" scrollable>
      <v-card>
        <v-card-title class="card-header">
          {{ $t('generic.lang_pleaseSelectDateRange') }}
        </v-card-title>
        <v-card-text class="pa-0 mx-0">
          <v-form ref="range_form" lazy-validation>
            <v-col cols="12">
              <BaseDateInput :rules="[
                  (v) => !!v,
                  (v) =>
                    (!!v && validTime) ||
                    $t('generic.lang_fromDateShouldBeLessThanToTime'),
                ]" type="date" dense outlined v-model="customCalendar.from" :label="$t('generic.lang_from')" />
            </v-col>
            <v-col cols="12">
              <BaseDateInput :rules="[
                  (v) => !!v,
                  (v) =>
                    (!!v && validTime) ||
                    $t('generic.lang_toDateShouldBeGreatThanFromTime'),
                ]" type="date" dense outlined v-model="customCalendar.to" :label="$t('generic.lang_to')" />
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions class="card-footer pt-0 pb-0 pr-2">
          <v-btn @click="cancelDateRange" color="error" text>
            {{ $t('generic.lang_cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn :disabled="!validTime" @click="saveUpDateRange" color="primary" text>
            {{ $t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- select calendar for item -->
    <v-dialog v-model="recurrence_dialog" max-width="400" overlay-opacity="0.9" scrollable>
      <v-card>
        <v-card-title class="card-header">
          {{ $t('generic.lang_customRecurences') }}
        </v-card-title>
        <v-card-text class="pa-0 mx-0">
          <v-form ref="recurrence_form" lazy-validation>
            <v-col cols="12">
              <v-row class="ma-0 pa-0">
                <v-col class="pb-0 px-1" cols="12">
                  <span>
                    {{ $t('generic.lang_repeatEach') }}
                  </span>
                </v-col>
                <v-col class="pb-0 px-1" cols="6">
                  <v-text-field class="ma-0" v-model="customCalendar.repeatEvery" :value="1" :min="1" dense outlined
                    prepend-inner-icon="filter_1" type="number" />
                </v-col>
                <v-col class="pb-0 px-1" cols="6">
                  <v-select v-model="customCalendar.every" value="day" :items="everyItems" item-text="name"
                    item-value="value" dense outlined />
                </v-col>

                <v-col class="pb-0 px-1 pt-0" cols="12">
                  <span>
                    {{ $t('generic.lang_repeatEach') }}
                  </span>
                </v-col>

                <v-col class="pa-0 px-1" cols="12">
                  <v-chip-group v-model="customCalendar.days" multiple color="primary">
                    <v-chip :value="getUntranslatedWeekDays()[index]" v-for="(day, index) in weekDays" :key="day">
                      {{ day.substring(0, 1) }}
                    </v-chip>
                  </v-chip-group>
                </v-col>

                <v-col class="pa-0 px-1 pt-4" cols="12">
                  <BaseDateInput v-model="customCalendar.to" :label="$t('generic.lang_endAt')" type="date" dense
                    outlined />
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions class="card-footer pt-0 pb-0 pr-2">
          <v-spacer />
          <v-btn @click="cancelCustomCalendar" color="error" text>
            {{ $t('generic.lang_cancel') }}
          </v-btn>
          <v-btn @click="confirmCustomCalendar" color="primary" text>
            {{ $t('generic.lang_next') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <EventbeeBulkMailComponent v-if="showBulkSendEmail" v-model="showBulkSendEmail" :event-data="currentItem"
      :plan-date="planDate" @updatePlans="getPlans()" />
  </v-container>
</template>

<script>
import Calendar from '@/views/accounting/Calendar';
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTag,
  faUserHeadset,
  faMailBulk,
} from '@fortawesome/pro-light-svg-icons';
import mixin from '@/mixins/KeyboardMixIns';
import EventbeeBulkMailComponent from './EventbeeBulkMailComponent';
import colorPicker from '../common/colorPicker';
import TicketsPriceListComponent from "./TicketsPriceListComponent";
import BaseDateInput from "@/components/common/BaseDateInput.vue";
import BaseTimeInput from "@/components/common/BaseTimeInput.vue";

library.add(faUserHeadset, faTag, faMailBulk);

export default {
  name: 'EventsCalendarComponent',
  components: {
    TicketsPriceListComponent,
    EventbeeBulkMailComponent,
    Calendar,
    'font-awesome-icon': FontAwesomeIcon,
    colorPicker,
    BaseDateInput,
    BaseTimeInput
  },
  watch: {
    'form.is_open_day': function (val) {
      if (!val) {
        this.form.has_arrival_time = false;
      }
    },
    'form.wareGroup': function () {
      this.getItemgroupItems();
    },
    'form.eventUUID': function (val) {
      if (val) {
        this.$nextTick(async () => {
          await this.getEvent(val);

          // set default alias
          if (this.form.alias === '' || !this.form.alias) {
            this.form.alias = this.selectedEventDetails.eventName;
          }
        });
      } else {
        this.selectedEventDetails = null;
      }
    },
    searchDate(val) {
      this.searchDatePicker = this.searchDate !==val;
			this.setCurrentWeek("date");
      this.getPlans();
		},
  },
  mixins: [mixin],
  computed: {
    everyItems(){
      return[
        {
          name:this.$t('generic.lang_day'),
          value:'day',
        },
        {
          name:this.$t('generic.lang_week'),
          value:'week',
        },
        {
          name:this.$t('generic.lang_month'),
          value:'month',
        },
      ]
    },
    expireItems(){
      return[
        {
          name: this.$t('generic.lang_none'),
          value: "",
        },
        {
          name:this.$t('generic.lang_day'),
          value:'day',
        },
        {
          name:this.$t('generic.lang_week'),
          value:'week',
        },
        {
          name:this.$t('generic.lang_month'),
          value:'month',
        },
        {
          name:this.$t('generic.lang_year'),
          value:'year',
        },
        {
          name:this.$t('generic.lang_date'),
          value:'date'
        }
      ]
    },
    quotaFieldLabel() {
      if (this.showEntryTicketFields) {
        if (this.form.is_open_day) return this.$t('generic.lang_quota_per_day');
        else return this.$t('generic.lang_quota_per_slot');
      }

      return this.$t('eventbee.lang_quota');
    },
    showEntryTicketFields() {
      return (
        this.selectedEventDetails && this.selectedEventDetails.eventType === 0
      );
    },
    filteredWareGroups() {
      if (this.groups)
        return this.groups.filter((group) =>
          group.name.includes(this.search_group)
        );
      else return [];
    },
    validTime() {
      if (this.customCalendar.from != null && this.customCalendar.to != null) {
        return (
          this.customCalendar.to,
          moment(this.customCalendar.from).unix() <
            moment(this.customCalendar.to).unix()
        );
      }
      return true;
    },
    filteredEvents() {
      if (
        this.selectedEventGroup === null ||
        (this.selectedEventGroup && Number(this.selectedEventGroup) <= 0)
      )
        return this.events;

      return this.events.filter((event) => {
        return event.groupID === this.selectedEventGroup;
      });
    },
    calendar: {
      get() {
        return (
          this.form.calendar.from +
          (this.form.calendar.to ? ' - ' + this.form.calendar.to : '') +
          (this.form.calendar.days && this.form.calendar.days.length > 0
            ? ' (' +
              this.form.calendar.days
                .map((d) => this.weekDaysTranslated[d])
                .join(',') +
              ')'
            : '')
        );
      },
    },
    dayData(){
      return (dayName)=>this.getWeekColumnTitles.find(d=> d.day === dayName)
    },
    getWeekColumnTitles(){
      return Array.from({length: 7},(el, index)=>{
        return {
          day: Object.keys(this.weekDaysTranslated)[index],
          date: this.$moment.utc(this.firstday,'YYYY-MM-DD').startOf('day').add(index, 'days').format('ddd MMM DD').replaceAll('.', ' '),
          timeStamp: this.$moment.utc(this.firstday,'YYYY-MM-DD').startOf('day').add(index, 'days').unix(),
          parsedDate: this.$moment.utc(this.firstday,'YYYY-MM-DD').startOf('day').add(index, 'days').format('YYYY-MM-DD')
        }
      })
    },
    filteredSeatMaps(){
      return this.seatMaps.map(item=> ({...item, name: `${item.name} - (${item.assignedSeats || 0} ${this.$t('eventbee.lang_seats')})`}))
    },
    formatedFirstDay(){
      return this.$moment.utc(this.firstday,'YYYY-MM-DD').format("dddd MMMM DD YYYY");
    },
    formatedLastDay(){
      return this.$moment.utc(this.lastday,'YYYY-MM-DD').format("dddd MMMM DD YYYY");
    },
  },
  data() {
    return {
      searchDate: null,
      searchDatePicker: false,
      events: [],
      eventGroups: [],
      selectedEvent: null,
      selectedEventGroup: null,
      serviceProviders: [],
      loadingEvents: false,
      loadingGroups: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      tags: [],
      pools: [],
      range_dialog: false,
      groups: [],
      availableItems: [],
      seatMaps: [],
      selectDeleteType: false,
      loadingItems: false,
      loadingLunchTypes: false,
      loadingShortNames: false,
      loadingSeatMaps: false,
      noteLoading: false,
      search_group: '',
      selectedDay: '',
      dialog: false,
      note_dialog: false,
      selectedItem: null,
      today: '',
      firstday: '',
      lastday: '',
      recurrence_dialog: false,
      curr: null,
      menuItems: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
      note: {
        note: '',
        day: '', // the day
      },
      customCalendar: {
        from: null,
        to: null /*if equal null means that the calendar will never finish*/,
        days: [] /*'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'*/,
        every: 'day' /*day , week , month*/,
        position:
          null /*we're using this attr only for months to indicate the position of the day => from 1 - 4 ( because each day can be repeated 4 time per month )*/,
        repeatEvery:
          1 /*we're using this attr when we want to repeat item very 2 weeks or 4 months .... SO WE HAVE 'every=week' and repeatEvery=3 that means repeat item every 3 weeks*/,
      },
      form: {
        uuid: '',
        validTill: "",
        validTillDate:"",
        askValidFrom:false,
        latestBookingBeforeClosing: null,
        assignedPool: null,
        seatingMapUUID: null,
        fromTime: null,
        toTime: null,
        fromTime2: null,
        toTime2: null,
        eventUUID: null,
        kontingent: null,
        providerID: null,
        wareGroup: null,
        color: '#FF0000',
        tags: [],
        item: null, // object
        price: null,
        priceList: [],
        alias: '' /*the new name to show in students portal*/,
        is_open_day: false,
        hide_slot_end_time: false,
        block_event_after_booking: false,
        overrideEventPriceList: false,
        calendar_steep: 15,
        number_of_slots: 1,
        has_arrival_time: false,
        overrideEventGroup: 0,
        calendar: {
          from: null,
          to: null /*if equal null means that the calendar will never finish*/,
          days: [] /*'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'*/,
          every: 'day' /*day , week , month*/,
          position:
            null /*we're using this attr only for months to indicate the position of the day => from 1 - 4 ( because each day can be repeated 4 time per month )*/,
          repeatEvery:
            1 /*we're using this attr when we want to repeat item very 2 weeks or 4 months .... SO WE HAVE 'every=week' and repeatEvery=3 that means repeat item every 3 weeks*/,
        },
      },
      gettingTheEvent: false,
      selectedEventDetails: null, // selected event details in the create form
      weekDays: [
        this.$t('generic.Montag'),
        this.$t('generic.Dienstag'),
        this.$t('generic.Mittwoch'),
        this.$t('generic.Donnerstag'),
        this.$t('generic.Freitag'),
        this.$t('generic.Samstag'),
        this.$t('generic.Sonntag'),
      ],
      weekDaysTranslated: {
        Monday: this.$t('generic.Montag'),
        Tuesday: this.$t('generic.Dienstag'),
        Wednesday: this.$t('generic.Mittwoch'),
        Thursday: this.$t('generic.Donnerstag'),
        Friday: this.$t('generic.Freitag'),
        Saturday: this.$t('generic.Samstag'),
        Sunday: this.$t('generic.Sonntag'),
      },
      lunchTypes: [], //lunch types goes here
      mealTypes: [],
      shortName: null,
      shortNames: [],
      edit: false,
      showBulkSendEmail: false,
      currentItem: null,
      planDate: '',
      select: [],
      items: [],
      search: '',
    };
  },
  methods: {
    updateKontingent(seatMap){
      this.form.kontingent = seatMap.assignedSeats || 0;
      this.form.seatingMapUUID = seatMap.uuid || null;
      
    },
    allowedDates(val){
      // Convert the date to a Moment object
      const date = moment(val)
      // Check if the date is the start of the week (Sunday or Monday, depending on your locale)
      return date.day() === date.startOf('isoWeek').day()
    },
    getUntranslatedWeekDays() {
      return [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
    },
    checkIfCanceled(item, day) {
      if (item.hasOwnProperty('cancelledDates') && item.cancelledDates) {
        return item.cancelledDates.includes(day);
      }
      return false;
    },
    checkIfExcluded(item, day) {
      if (item.hasOwnProperty('excludedDates') && item.excludedDates) {
        return item.excludedDates.includes(day);
      }
      return false;
    },
    updateTags() {
      this.$nextTick(() => {
        this.form.tags.push(...this.search.split(','));
        this.$nextTick(() => {
          this.search = '';
        });
      });
    },
    showBulkSendEmailDialog(item, day) {
      this.showBulkSendEmail = true;
      this.planDate = moment(this.curr.getTime())
        .startOf('isoWeek')
        .add(day - 1, 'days')
        .format('DD.MM.YYYY');
      this.currentItem = item;
    },
    getEvents() {
      this.loadingEvents = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.EVENT.GET, {
          all: false,
        })
        .then((res) => {
          if (res.data.status) {
            this.events = res.data.data;
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingEvents = false;
        });
    },
    getSeatMaps() {
      this.loadingSeatMaps = true;
      this.axios
      .post(ENDPOINTS.EVENTBEE.SEATING_MAP.GET)
        .then((res) => {
          if (res.data.status) {
            this.seatMaps = res.data.data;
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingSeatMaps = false;
        });
    },
    getEventGroups() {
      this.loadingGroups = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.GET, {})
        .then((res) => {
          this.eventGroups = res.data.data;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },
    getServiceProviders() {
      this.loadingGroups = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SERVICEPROVIDERS, {})
        .then((res) => {
          this.serviceProviders = res.data.users;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },
    async getItemgroupItems() {
      if (this.form.wareGroup && parseInt(this.form.wareGroup) > 0) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        let items = await this.$store.dispatch(
          'items/getItemsByItemGroupIDSortByName',
          this.form.wareGroup
        );
        if (!Array.isArray(items)) this.availableItems = [];
        else this.availableItems = [...items];
      } else return [];
    },
    async wareGroups() {
      let itemgroups = await this.$store.dispatch('itemgroups/getItemgroups');

      this.groups = itemgroups
        .map((itemgroup) => {
          return {
            id: itemgroup.id,
            name: itemgroup.name,
          };
        })
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
    },

    /**
     * convert H:m to seconds
     * */
    hmToSecondsOnly(time = '') {
      if (time != null) {
        let arr = time.split(':');
        if (Array.isArray(arr) && arr.length >= 2) {
          return parseInt(arr[0]) * 3600 + parseInt(arr[1]) * 60;
        }
      }
      return 0;
    },
    setUpEdit(item) {
      this.form.eventUUID = item.eventUUID;
      this.form.uuid = item.uuid;
      this.form.eventName = item.eventName;
      this.form.assignedPool = item.assignedPool;
      this.form.seatingMapUUID = item.seatingMapUUID;
      this.form.alias = item.alias || item.eventName;

      this.form.fromTime = item.fromTime.substring(0, 5);
      this.form.fromTime2 = item.fromTime2?.substring(0, 5);
      this.form.toTime = item.toTime.substring(0, 5);
      this.form.toTime2 = item.toTime2?.substring(0, 5);
      this.form.providerID = item.providerId;
      this.form.tags = item.tags;
      this.form.kontingent = item.kontingent;

      this.form.calendar.from = moment.unix(item.calendarFrom).format('YYYY-MM-DD')
      this.form.calendar.to = moment.unix(item.calendarTo).format('YYYY-MM-DD')

      this.form.calendar.days = item.days;
      this.form.calendar.every = item.every;
      this.form.calendar.repeatEvery = item.repeatEvery;

      this.mealTypes = item.mealTypeUUIDs;
      this.shortName = item.uuidMenuShortName;

      this.form.is_open_day = item.is_open_day;
      this.form.overrideEventGroup = item.overrideEventGroup;
      this.form.block_event_after_booking = item.block_event_after_booking;
      this.form.hide_slot_end_time = item.hide_slot_end_time;
      this.form.number_of_slots = item.number_of_slots;
      this.form.calendar_steep = item.calendar_steep;
      this.form.has_arrival_time = item.has_arrival_time;
      this.form.color = item.color;
      this.form.latestBookingBeforeClosing = item.latestBookingBeforeClosing;
      this.form.overrideEventPriceList = item.overrideEventPriceList;
      this.form.priceList = Array.isArray(item.priceList)?structuredClone(item.priceList):[];
      this.form.validTill = this.$moment(item.validTime,'YYYY-MM-DD').isValid()?'date':item.validTime;
      this.form.validTillDate = this.$moment(item.validTime,'YYYY-MM-DD').isValid()?item.validTime:null;
      this.form.askValidFrom = item.askValidFrom;

      //show dialog
      this.edit = true;
      this.dialog = true;
    },
    cancelDateRange() {
      if (this.$refs.range_form !== undefined)
        this.$refs.range_form.reset();
      this.range_dialog = false;
    },
    cancelCustomCalendar() {
      if (this.$refs.recurrence_form !== undefined)
        this.$refs.recurrence_form.reset();
      this.recurrence_dialog = false;
    },
    confirmCustomCalendar() {
      this.form.calendar = Object.assign({}, this.customCalendar);
      this.recurrence_dialog = false;
      this.form.calendar.position = null;
      this.$refs.recurrence_form.reset();
    },
    saveUpDateRange() {
      if (!this.$refs.range_form.validate()) return;
      this.form.calendar = Object.assign({}, this.customCalendar);
      this.range_dialog = false;
    },
    openCustomCalendar() {
      this.customCalendar = Object.assign({}, this.form.calendar);
      this.recurrence_dialog = true;
    },
    openDateRange(){
      this.customCalendar = Object.assign({}, this.form.calendar);
      this.range_dialog = true;
    },
    checkDelete(elt, day) {
      this.selectedDay = new Date(
          this.$moment(day,'DD.MM.YYYY').unix() * 1000
      );

      this.selectedItem = elt;
      this.selectDeleteType = true;
      // @TODO until i figure out a cheap way to calculate the plan's recurrences to decide whether show the delete dialog or delete directly
      // if (elt.days && elt.days.length >= 2) {
      //   this.selectedItem = elt;
      //   this.selectDeleteType = true;
      // } else
      //   this.deleteItem(elt.uuid, 'full', this.selectedDay.getTime() / 1000);
    },
    deleteItem(uuid, deleteType, date = new Date().getTime() / 1000) {
      this.$swal({
        title: this.$t('generic.lang_delete'),
        text:
          this.$t('generic.lang_delete') + ' ' + this.$t('generic.lang_plan'),
        icon: 'warning',
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios
            .post(ENDPOINTS.EVENTBEE.PLANS.DELETE, {
              UUID: uuid,
              deleteType: deleteType,
              excludedDayTS: Math.floor(date),
            })
            .then((res) => {
              if (res.data.status === 'SUCCESS') {
                Events.$emit('showSnackbar', {
                  message: this.$t('generic.lang_success'),
                  color: 'success',
                });
                this.getPlans();
              } else {
                Events.$emit('showSnackbar', {
                  message: this.$t('generic.lang_errorOccurred'),
                  color: 'error',
                });
              }
            })
            .catch(() => {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            });
        },
      });
    },
    // a working eventplans generator still in testing, needs to be optimized or done in the backend
    // getPlans() {
    //   this.loadingItems = true;
    //
    //
    //   let startCalendar = this.$moment.utc(this.firstday,'dddd MMMM DD YYYY').startOf('day');// start of the week
    //   let endCalendar = this.$moment.utc(this.lastday,'dddd MMMM DD YYYY').endOf('day');// end of the week
    //   this.axios
    //       .post(ENDPOINTS.EVENTBEE.PLANS.GET, {
    //         start: startCalendar.unix(),
    //         end: endCalendar.unix(),
    //       })
    //       .then((res) => {
    //         if (res.data.status) {
    //           let items = res.data.data;
    //           /*we clear data to avoid items duplication*/
    //           this.menuItems = {
    //             Monday: [],
    //             Tuesday: [],
    //             Wednesday: [],
    //             Thursday: [],
    //             Friday: [],
    //             Saturday: [],
    //             Sunday: [],
    //           };
    //           items.forEach((plan) => {
    //             let planFrom = this.$moment.utc(plan.calendarFrom * 1000);// event plan start date
    //             let planTo =  this.$moment.utc(plan.calendarTo * 1000);// event plan end date
    //             let days = plan.days || []; // array of days where the plan is available ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    //             let every = plan.every || 'day'; // key to repeat every, can be 'day','week','month';
    //             let repeatEvery = plan.repeatEvery === null || plan.repeatEvery === 0 ? 1 : (every === 'month' && plan.repeatEvery === 2? 1 : plan.repeatEvery); // number of times that should the eky be repeated after
    //
    //             if (plan.excludedDates)
    //               plan.excludedDates = JSON.parse(plan.excludedDates).map((d) => {
    //                 return moment.utc(d*1000).format('YYYY-MM-DD');
    //               });
    //
    //             if (plan.cancelledDates)
    //               plan.cancelledDates = plan.cancelledDates.map((d) => {
    //                 return moment.utc(d,'DD.MM.YYYY').format('YYYY-MM-DD');
    //               });
    //
    //             let currentDay = planFrom.clone();
    //             while (currentDay.isSameOrBefore(planTo) && currentDay.isSameOrBefore(endCalendar)) {
    //               let currentDayName = Object.keys(this.weekDaysTranslated)[currentDay.format('E')-1];
    //               if (every === 'day') {
    //                 if (currentDay.isBetween(startCalendar, endCalendar, null, '[]') && days.includes(currentDayName) && !this.checkIfExcluded(plan,this.dayData(currentDayName).parsedDate)) {
    //                   this.menuItems[currentDayName].push(plan)
    //                 }
    //                 currentDay.add(repeatEvery, 'days');
    //               } else if (every === 'week') {
    //                 days.forEach(d=>{
    //                   let dayTmp = this.$moment.unix(this.dayData(d).timeStamp).utc();
    //                   if (dayTmp.isBetween(planFrom, planTo, null, '[]') && currentDay.isBetween(startCalendar, endCalendar, null, '[]') && !this.checkIfExcluded(plan,this.dayData(d).parsedDate)) {
    //                     this.menuItems[d].push(plan)
    //                   }
    //                 })
    //                 currentDay.add(repeatEvery, 'weeks');
    //               } else if (every === 'month') {
    //                 if (currentDay.isBetween(startCalendar, endCalendar, null, '[]') && days.includes(currentDayName) && !this.checkIfExcluded(plan,this.dayData(currentDayName).parsedDate)) {
    //                   this.menuItems[currentDayName].push(plan)
    //                 }
    //                 currentDay.add(1, 'days');
    //                 if(Number(currentDay.format('DD')) === 1){
    //                   currentDay.add(repeatEvery, 'months');
    //                 }
    //               } else {
    //                 throw new Error("Invalid value for 'every' parameter.");
    //               }
    //             }
    //           });
    //         } else {
    //           Events.$emit('showSnackbar', {
    //             message: this.$t('generic.lang_errorOccurred'),
    //             color: 'error',
    //           });
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //         Events.$emit('showSnackbar', {
    //           message: err.message,
    //           color: 'error',
    //         });
    //       })
    //       .finally(() => {
    //         this.loadingItems = false;
    //       });
    // },
    getPlans() {
      this.loadingItems = true;

      let startFilter = moment(this.firstday,'YYYY-MM-DD').startOf('day').unix();
      let endFilter = moment(this.lastday,'YYYY-MM-DD').endOf('day').unix();

      this.axios
        .post(ENDPOINTS.EVENTBEE.PLANS.GET, {
          start: startFilter,
          end: endFilter,
        })
        .then((res) => {
          if (res.data.status) {
            let items = res.data.data;
            /*we clear data to avoid items duplication*/
            this.menuItems = {
              Monday: [],
              Tuesday: [],
              Wednesday: [],
              Thursday: [],
              Friday: [],
              Saturday: [],
              Sunday: [],
            };
            items.forEach((elt) => {
              let startCal = elt.calendarFrom;
              let endCal =  elt.calendarTo;

              if (elt.excludedDates)
                elt.excludedDates = JSON.parse(elt.excludedDates).map((d) => {
                  return moment.unix(d).format('DD.MM.YYYY');
                });
              if (elt.days !== null && elt.days !== '' && elt.days.length > 0) {
                elt.days.forEach((d) => {
                  // TODO: should be translated
                  let index = Object.keys(this.weekDaysTranslated).indexOf(d);
                  if(moment.unix(startFilter).add(index,'days').startOf('day').isSameOrAfter(moment.unix(startCal).startOf('day')) &&
                      moment.unix(startFilter).add(index,'days').isSameOrBefore(moment.unix(endCal))||
                      (
                          moment.unix(endFilter).isSameOrAfter(moment.unix(startCal)) &&
                          moment.unix(endFilter).isSameOrBefore(moment.unix(endCal)) &&
                          moment.unix(startFilter).isSameOrAfter(moment.unix(endCal)) &&
                          moment.unix(startFilter).isSameOrBefore(moment.unix(startCal))
                      )
                  ) {
                    this.menuItems[d].push(elt);
                  }
                });
              }
            });
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },
    getNote() {
      this.noteLoading = true;
      this.axios
        .post(ENDPOINTS.KANTINE.MENU.NOTE.GET, {
          unixTimestamp: this.selectedDay.getTime() / 1000,
          note: this.note.note,
        })
        .then((res) => {
          this.note.note = res.data.messageOfTheDay;
          this.note.uuid = res.data.uuid;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.noteLoading = false;
        });
    },
    saveNote() {
      this.noteLoading = true;
      let dt = {
        unixTimestamp: this.selectedDay.getTime() / 1000,
        note: this.note.note,
      };

      /*here we're checking if this note is already exist so we can update it*/
      if (
        this.note.uuid !== null &&
        this.note.uuid !== undefined &&
        this.note.uuid !== ''
      )
        dt.uuid = this.note.uuid;

      this.axios
        .post(ENDPOINTS.KANTINE.MENU.NOTE.UPDATE, dt)
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_success'),
              color: 'success',
            });
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.noteLoading = false;
        });
    },
    selectDay(ds, every = 'day', position = null) {
      if (Array.isArray(ds)) {
        this.form.calendar.days = ds.map((d) => {
          return d;
        });

        this.form.calendar.every = every;
        this.form.calendar.repeatEvery = 1;
        this.form.calendar.position = null;
      } else {
        this.form.calendar.days = [ds];
        this.form.calendar.every = every;
        this.form.calendar.position = position;
        this.form.calendar.repeatEvery = 1;
      }

      /**
       * show date range dialog
       */
      this.openDateRange();
    },
    setPrice() {
      this.form.price = this.form.item.sellPrice;
      this.form.alias = this.form.item.name;
    },
    async getEvent(uuid) {
      this.gettingTheEvent = true;
      await this.axios
        .post(ENDPOINTS.EVENTBEE.EVENT.GET, {
          UUID: uuid,
          all: true,
        })
        .then((res) => {
          if (res.data.status) {
            this.selectedEventDetails = res.data.data;
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.gettingTheEvent = false;
        });
    },
    create() {
      if (!this.$refs.form.validate()) return;
      this.loadingItems = true;
      let fr = {
        fromTime: this.form.fromTime,
        overrideEventGroup: this.form.overrideEventGroup?this.form.overrideEventGroup:0,
        fromTime2: this.form.fromTime2,
        toTime: this.form.toTime,
        toTime2: this.form.toTime2,
        eventUUID: this.form.eventUUID,
        tags: this.form.tags,
        providerID: this.form.providerID,
        kontingent: this.form.kontingent,
        assignedPool: this.form.assignedPool,
        seatingMapUUID: this.form.seatingMapUUID,
        from: new Date(this.form.calendar.from).getTime() / 1000,
        to: new Date(this.form.calendar.to).getTime() / 1000,
        days: this.form.calendar.days,
        every: this.form.calendar.every,
        repeatEvery: this.form.calendar.repeatEvery,
        position: this.form.calendar.position,
        priceList: this.form.priceList,
        overrideEventPriceList: this.form.overrideEventPriceList,
        is_open_day: this.form.is_open_day,
        calendar_steep: this.form.calendar_steep,
        number_of_slots: this.form.number_of_slots,
        latestBookingBeforeClosing: this.form.latestBookingBeforeClosing,
        alias: this.form.alias,
        has_arrival_time: this.form.has_arrival_time,
        hide_slot_end_time: this.form.hide_slot_end_time,
        block_event_after_booking: this.form.block_event_after_booking,
        color: this.form.color,
        validTill: this.form.validTill === 'date'? this.form.validTillDate : this.form.validTill,
        askValidFrom: this.form.askValidFrom
      };

      if (this.edit) fr.uuid = this.form.uuid;
      this.axios
        .post(ENDPOINTS.EVENTBEE.PLANS.UPDATE, fr)
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            this.close();
            this.getPlans();
          } else {
            Events.$emit('showSnackbar', {
              message: res.data.msg || res.data.status,
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },
    close() {
      this.$refs.form.reset();
      this.cancelCustomCalendar();
      this.form.calendar.from = moment().format('YYYY-MM-DD');
      this.form.calendar.to = moment().format('YYYY-MM-DD');
      this.dialog = false;
      this.edit = false;
      this.selectedEventDetails = null;
    },

    getCompleteDayName(day, is_string = false) {
      //get Day name by offset
      if (
        !is_string &&
        day &&
        !Number.isNaN(parseInt(day)) &&
        parseInt(day) > -1
      ) {
        return this.getUntranslatedWeekDays()[day];
      } else if (is_string) {
        let index = Object.keys(this.weekDaysTranslated).indexOf(day);
        if (index > -1) {
          return this.getUntranslatedWeekDays()[index];
        }
        return this.getUntranslatedWeekDays()[0]; //use monday as default day
      }
    },
    open(day, index) {

      // handling the end of the year and start of the year case
      const firstDayYear = new Date(this.firstday).getFullYear();
      const lastDayYear = new Date(this.lastday).getFullYear()
      let dt = new Date(day + ' ' + (firstDayYear >lastDayYear?firstDayYear:lastDayYear));
      let tmp = moment.unix(Math.floor(dt.getTime() / 1000));
      this.selectedDay = new Date(tmp.format('YYYY-MM-DD'));
      this.form.calendar.from = tmp.format('YYYY-MM-DD');
      this.form.calendar.to = tmp.format('YYYY-MM-DD');
      this.form.calendar.days = [
        this.getUntranslatedWeekDays()[index - 1]
      ];

      this.dialog = true;
    },
    getTranslatedDay(i) {
      return this.$moment(this.curr.getTime())
          .startOf('isoWeek')
          .add(i - 1, 'days')
          .format('ddd MMM DD')
    },
    getDay(i) {
      /*let dt = new Date(this.curr.getTime())
 let first = dt.getDate() - dt.getDay()
 dt.setDate(first)*/
      let dt = new Date(
        moment(this.curr.getTime())
          .startOf('isoWeek')
          .add(i - 1, 'days')
          .valueOf()
      );

      return (
        dt.toDateString().split(' ')[0] +
        ' ' +
        dt.toDateString().split(' ')[1] +
        ' ' +
        dt.toDateString().split(' ')[2]
      );
    },
    getFormatedDate(date) {
      return moment(this.curr.getTime())
        .startOf('isoWeek')
        .add(date - 1, 'days')
        .format('DD.MM.YYYY');
    },
    setCurrentWeek(next = 0) {
      if (this.curr === null || next == 0) {
        this.curr = new Date();
        this.selectedDay = new Date(moment().format('YYYY-MM-DD'));
      }

      //  let first = this.curr.getDate() - this.curr.getDay() + 1 // First day is the day of the month - the day of the week
      if (next > 0) {
        this.curr = new Date(
          moment(this.curr.getTime()).startOf('isoWeek').add(7, 'days').valueOf()
        );
      } else if (next < 0) {
        this.curr = new Date(
          moment(this.curr.getTime()).startOf('isoWeek').add(-7, 'days').valueOf()
        );
      }else if (next === "date"){
        this.curr = new Date(
          moment(this.searchDate).startOf('isoWeek').valueOf()
        );
      }

      this.firstday = this.$moment(this.curr.getTime())
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      this.searchDate = this.$moment(this.curr.getTime()).format('YYYY-MM-DD');
      this.lastday = this.$moment(this.curr.getTime())
        .endOf('isoWeek')
        .format('YYYY-MM-DD');
    },
    loadPools(){
      this.loading = false;
      this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.POOL.GET,).then((res) => {
        if (res.status === 200) {
          this.pools = res.data
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.setCurrentWeek();
    this.form.calendar.from = moment
      .unix(Math.floor(new Date().getTime() / 1000))
      .format('YYYY-MM-DD');
    this.form.calendar.to = moment
      .unix(Math.floor(new Date().getTime() / 1000))
      .format('YYYY-MM-DD');

    this.getPlans();
    this.loadPools();
    this.getEvents();
    this.getEventGroups();
    this.getServiceProviders();
    this.getSeatMaps();
  },
};
</script>

<style scoped></style>
